import React, { useState } from 'react';

import { useAppInfo } from 'AppState';
import Logo from 'logo/Logo';
import {
  groupedNavLinkAdvisor,
  groupedNavLinks,
} from 'nav/menu';
import ProfileSettings from 'nav/ProfileSettings';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  Button,
  Paper,
  Typography,
} from '@mui/material';

export default function LeftSidebarMainExpandedNew({ handleSidebar, handleHover }) {
  const { dispatchSidebar, stateApp } = useAppInfo();
  const { onboarding } = stateApp;
  const { userRole } = onboarding;
  const navigate = useNavigate();
  const location = useLocation();
  const pageName = location.pathname.split('/')[1];
  
  // Select navigation links based on user role
  const navLinks = userRole === 'advisor' ? groupedNavLinkAdvisor : groupedNavLinks;
  const [mouseEnter, setMouseEnter] = useState(true);

  return (
    <>
      <Paper
        elevation={1}
        id="expandedMenuMain"
        style={{
          background: 'var(--bgWoodSmoke)',
          position: 'relative',
          width: 'calc(10% + 50px)',
          minWidth: '250px',
          height: '100vh',
          zIndex: 100,
          overflow: mouseEnter ? 'auto' : 'hidden',
          borderRadius: 0,
          top: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            padding: '0 1rem',
            flex: 1,
            position: 'relative',
          }}
        >
          <div style={{padding: '1rem 0',display:"flex",flexDirection:"column",gap:"0.25rem"}}>
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              alignItems: 'center',
              position: 'sticky',
              top: 0,
              zIndex: 10,
              background: 'var(--bgWoodSmoke)',
            }}
          >
            <Logo color="white" type='long' width='135px' height='15px'/>
            
            <Button
              sx={{
                marginLeft: 'auto',
                minWidth: '0',
                padding: 0,
                flexDirection: 'column',
                textTransform: 'none',
                color:"#ddd"
              }}
              onClick={() => dispatchSidebar({ sidebarLeftExpanded: false })}
            >
              <ArrowBackIosNewIcon fontSize='small'/>
            </Button>
           
          </div>
          <Typography 
          className='gradient_text'
          style={{fontSize:"0.9rem"}}>
            {userRole === "advisor" && "Advisor"} Beta</Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', flex: 1 }}>
            {Object.keys(navLinks).map((category, catIndex) => (
              <div key={catIndex} style={{padding:!["Home","AI"].includes(category) && "1rem 0"}}>
                {!["Home","AI"].includes(category) &&
                <Typography
                  style={{
                    fontWeight: 'bold',
                    color: '#ddd',
                    marginBottom: '0.5rem',
                    fontSize: '0.7rem',
                  }}
                >
                  {category}
                </Typography>}

                <div style={{display:"flex",flexDirection:"column",gap:"0.25rem"}}>
                {navLinks[category].map((option, index) => (
                  <Button
                    key={index}
                    id={`link-${option.name}`}
                    onPointerEnter={() => handleHover(option.name)}
                    onMouseEnter={() => handleHover(option.name)}
                    onClick={() => {
                      handleHover(option.name);
                      if (!option.menu) {
                        handleSidebar(option.link);
                      }
                    }}
                    sx={{
                      display: 'flex',
                      color: pageName === option.link ? 'var(--selectBlue)' : '#ddd',
                      padding: '0.4rem',
                      flex: 1,
                      fontSize: '1.2rem',
                      gap:"1rem",
                      justifyContent: 'flex-start',
                      background: pageName === option.link && '#fafafa',
                      alignItems: 'center',
                      '&:hover': {
                        background: option.link === pageName ? '#F8F8F8' : '#fafafa',
                        color: option.link !== pageName && 'black',
                      },
                    }}
                  >
                    {option?.icon}
                    <Typography
                      style={{
                        textAlign: 'left',
                        flex: 1,
                        fontSize: '0.85rem',
                        fontWeight: option.link === pageName && 'bold',
                      }}
                    >
                      {option.name}
                    </Typography>
                    {option?.comment && 
                    <div style={{
                      top:"50%",
                      transform:"translateY(-50%)",
                      position:"absolute",
                      right:"0px",
                      zIndex:"1",
                      borderRadius:"5px",
                      border:"2px solid gray"
                    }}>
                      <Typography style={{fontSize:"0.7rem",color:"gray"}}>Demo</Typography>
                    </div>}
                  </Button>
                ))}
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              marginTop: 'auto',
              padding: '1rem 0rem',
              width: '100%',
              position: 'sticky',
              bottom: 0,
              zIndex: 1000,
              background: 'var(--bgWoodSmoke)',
            }}
          >
            <ProfileSettings type="long" />
          </div>
        </div>
      </Paper>
    </>
  );
}

