import React, { useState } from 'react';

import { useAppInfo } from 'AppState';
import Logo from 'logo/Logo';
import {
  navLinkAdvisor,
  navLinkCategories,
} from 'nav/menu';
import ProfileSettings from 'nav/ProfileSettings';

import { Button } from '@mui/material';

import LeftSidebarMainSelectMenu from './LeftSidebarMainSelectMenu';

export default function LeftSidebarMainCollapsed({pageName,handleHover,handleSidebar}) {
  const {dispatchSidebar,stateSidebar,stateApp} = useAppInfo()
  const {navLinksSelected} = stateSidebar
  const {onboarding} = stateApp
  const {userRole} = onboarding
  const [mouseEnter,setMouseEnter] = useState(false)

  // Select navigation links based on user role
  const baseNavLinks = userRole === 'advisor' ? navLinkAdvisor : navLinkCategories
  const navLinksDisplay = baseNavLinks.filter(obj=>navLinksSelected.includes(obj?.link))

  return (
    <div
    onMouseEnter={()=>setMouseEnter(true)}
    onMouseLeave={()=>setMouseEnter(false)}
    style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      overflow: 'hidden',
      height: '100%',
      zIndex:1,
      color:"#ddd"
    }}
  >
      {/* <Button
      sx={{
        minWidth: '0',
        padding: '0',
      }}
      onMouseEnter={() => dispatchSidebar({ sidebarLeftExpanded: true })}
    >
         <Logo/>

    </Button> */}
    <div 
     onMouseEnter={() => dispatchSidebar({ sidebarLeftExpanded: true })}
    style={{display:"flex",justifyContent:"center",alignItems:"center",cursor:"pointer"}}>
    <Logo color="#50ecec" type='short' width='25px' height='25px'/>
    </div>

    {/* <Button
      sx={{
        minWidth: '0',
        padding: '0',
        color:"#ddd"
      }}
      onMouseEnter={() => dispatchSidebar({ sidebarLeftExpanded: true })}
    >
      <MenuOpenIcon style={{ transform: 'rotate(180deg)' }} />
    </Button> */}
    <div style={{flex:1,overflow:mouseEnter ? "auto" : "hidden",display:"flex"
      ,flexDirection:"column",gap:"0.5rem",paddingBottom:"20px"}}>
    {navLinksDisplay.map((option, index) => (
      <React.Fragment key={index}>
        <Button
          id={`link-${option?.name}`}
          aria-describedby={option.name}
          onPointerEnter={() => handleHover(option.name)}
          onMouseEnter={() => handleHover(option.name)}
          onClick={() => {
            if (!option.menu) {
              handleSidebar(option.link);
            }
          }}
          sx={{
            position: 'relative',
            minWidth: '0',
            width: '100%',
            flexDirection: 'column',
            textTransform: 'none',
            background: option.link === pageName && '#F8F8F8',
            color: option.link !== pageName && '#ddd',
            padding: '0.5rem',
            borderRadius: 0,
            '&:hover': {background:option.link === pageName ? '#F8F8F8' :"#fafafa",color:option.link !== pageName && 'black'}
          }}
        >
         {option?.icon}  
        </Button>
      </React.Fragment>
    ))}
      <LeftSidebarMainSelectMenu/>
    </div>
    <div
      style={{
        marginTop: 'auto',
        paddingBottom: '0rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',paddingBottom:"0.5rem",zIndex:10000
      }}
    >

      <ProfileSettings/>
    </div>
  </div>
  )
}
