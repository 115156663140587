import React from 'react';

import { useAppInfo } from 'AppState';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { Typography } from '@mui/material';

import Logo from '../logo/Logo';
import HomeBackground from './HomeBackground';
import SocialMedia from './SocialMedia';

export default function HomeFooterBig() {
    const {stateApp} = useAppInfo()
    const navigate = useNavigate()
    const location = useLocation()
    const page = location.pathname.split("/")[1]
    function handleNavigate(v){
        navigate(`/${v}`)
    }
    const menuOptions = stateApp.isLoggedIn ? 
    ["Home","Product","Pricing","Profile","Support","About","Contact","Disclaimer","Terms"] : 
    ["Contact","Disclaimer","Terms"]

    const color = "black"
 
    // ["Features","Pricing","Support","About","Contact","Disclaimer","Terms"]
  return (
    <div style={{width:"100%"
    ,display:"flex",flexDirection:"column"
    ,alignItems:"center",gap:"2rem",padding:"4rem",boxSizing:"border-box"
    ,zIndex:100,position:"relative",minHeight:"100vh"}}>
      <HomeBackground/>
      
    {/* <ProductIntro/> */}
    <div>
    <Logo color={color} height={"35px"} width="250px" type='long'/>
    <Typography style={{color:color,textAlign:"center",marginTop:"0.5rem"}}>This is a Beta Version.</Typography>
    </div>
   
    <div style={{display:"flex",gap:"2rem",alignItems:"center",position:"relative"}}>
            {menuOptions.map(obj=>
            <Typography 
            onClick={()=>handleNavigate(obj)}
            key={obj} 
            style={{cursor:"pointer",color:obj===page ? 
            "var(--lightBlue)":color}}>{obj}</Typography>)}
          
          </div>
          <Typography style={{color:color,width:"40%",textAlign:"center"}}>Please read the Terms and Disclaimer before using the product.</Typography>
    <SocialMedia color={color}/>
    </div>
  )
}
