import React from 'react';

export default function LogoLong({ color = 'white', width="250px", height = '35px' }) {
  return (
    <div style={{height:height, width: width, margin: '0 auto',display:"flex",justifyContent:"center",alignItems:"center" }}>
      <svg 
        viewBox="0 0 498 70" 
        preserveAspectRatio="xMidYMid meet"
        style={{ width: '100%', height: 'auto' }}
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
<path d="M457.955 6.56381L472.514 31.1663L486.981 6.56381C487.81 5.0895 489.469 4.16806 491.127 4.16806H494.444C496.472 4.16806 497.854 5.82666 497.854 7.66954C497.854 8.22241 497.762 8.86742 497.393 9.42029L477.674 41.7629V65.2597C477.674 67.1026 476.108 68.669 474.265 68.669H469.75C467.907 68.669 466.34 67.1026 466.34 65.2597V41.7629L446.806 9.42029C446.437 8.86742 446.253 8.22241 446.253 7.5774C446.253 5.82666 447.727 4.16806 449.754 4.16806H453.717C455.375 4.16806 457.034 5.0895 457.955 6.56381Z" fill={color}/>
<path d="M386.253 22.3205V65.2597C386.253 67.1026 384.687 68.669 382.844 68.669H378.329C376.486 68.669 374.92 67.1026 374.92 65.2597V7.5774C374.92 5.73451 376.486 4.16806 378.329 4.16806H383.581C385.24 4.16806 386.991 5.0895 388.004 6.47167L408.368 34.2992L427.81 6.47167C428.732 5.0895 430.483 4.16806 432.141 4.16806H436.656C438.499 4.16806 440.066 5.73451 440.066 7.5774V65.2597C440.066 67.1026 438.499 68.669 436.656 68.669H432.233C430.39 68.669 428.824 67.1026 428.824 65.2597V22.044L411.962 45.7251C410.764 47.3837 409.197 48.213 407.631 48.213C406.064 48.213 404.406 47.3837 403.208 45.7251L386.253 22.3205Z" fill={color}/>
<path d="M291.408 0.887177H295.186C297.029 0.887177 298.596 2.45363 298.596 4.29651V65.2597C298.596 67.1025 297.029 68.669 295.186 68.669H291.961C290.303 68.669 288.921 67.8397 287.815 66.4575L254.09 30.5407V58.698C254.09 60.5409 252.524 62.1073 250.681 62.1073H246.903C245.06 62.1073 243.493 60.5409 243.493 58.698V14.139C243.493 12.2962 245.06 10.7297 246.903 10.7297H250.128C251.786 10.7297 253.169 11.559 254.274 12.9412L287.999 49.0423V4.29651C287.999 2.45363 289.566 0.887177 291.408 0.887177Z" fill={color}/>
<mask id="mask0_6154_5894" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="174" y="2" width="64" height="31">
<path fillRule="evenodd" clipRule="evenodd" d="M237.291 2.56119H174.623V29.4191H175.151C175.133 29.7217 175.122 30.0072 175.118 30.2743C175.092 31.7293 176.321 32.7754 177.776 32.7628L182.703 32.7204C184.452 32.7053 185.893 31.1785 186.187 29.4537C186.189 29.4422 186.19 29.4306 186.192 29.4191H224.823C225.128 31.132 226.562 32.6418 228.302 32.6568L233.228 32.6993C234.684 32.7118 235.913 31.6657 235.887 30.2107C235.882 29.9624 235.873 29.6982 235.858 29.4191H237.291V2.56119Z" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_6154_5894)">
<path d="M174.936 33.3577C174.936 16.3437 187.424 3.1012 205.444 3.1012C222.877 3.1012 236.036 15.0865 236.036 33.3577C236.036 50.9585 222.877 63.6143 205.444 63.6143C188.011 63.6143 174.936 51.629 174.936 33.3577ZM185.832 33.3577C185.832 45.0916 194.464 53.9758 205.444 53.9758C217.765 53.9758 225.14 44.9239 225.14 33.3577C225.14 21.2048 216.507 12.7397 205.444 12.7397C194.213 12.7397 185.832 21.2048 185.832 33.3577Z" fill={color}/>
</g>
<mask id="mask1_6154_5894" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="306" y="2" width="63" height="31">
<path fillRule="evenodd" clipRule="evenodd" d="M368.69 2.56119H306.021V29.4191H306.549C306.532 29.7217 306.521 30.0072 306.516 30.2743C306.49 31.7293 307.72 32.7754 309.175 32.7628L314.101 32.7204C315.851 32.7053 317.291 31.1785 317.585 29.4537C317.587 29.4422 317.589 29.4306 317.591 29.4191H356.222C356.527 31.132 357.961 32.6418 359.701 32.6568L364.627 32.6993C366.082 32.7118 367.312 31.6657 367.286 30.2107C367.281 29.9624 367.272 29.6982 367.256 29.4191H368.69V2.56119Z" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask1_6154_5894)">
<path d="M306.335 33.3577C306.335 16.3437 318.823 3.1012 336.843 3.1012C354.276 3.1012 367.434 15.0865 367.434 33.3577C367.434 50.9585 354.276 63.6143 336.843 63.6143C319.41 63.6143 306.335 51.629 306.335 33.3577ZM317.23 33.3577C317.23 45.0916 325.863 53.9758 336.843 53.9758C349.163 53.9758 356.539 44.9239 356.539 33.3577C356.539 21.2048 347.906 12.7397 336.843 12.7397C325.612 12.7397 317.23 21.2048 317.23 33.3577Z" fill={color}/>
</g>
<mask id="mask2_6154_5894" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="174" y="38" width="64" height="31">
<path fillRule="evenodd" clipRule="evenodd" d="M237.291 68.5733H174.623V41.7154H175.151C175.133 41.4129 175.122 41.1274 175.118 40.8604C175.092 39.4055 176.321 38.3593 177.776 38.3719L182.703 38.4144C184.452 38.4294 185.893 39.9562 186.187 41.681C186.189 41.6925 186.19 41.7039 186.192 41.7154H224.823C225.128 40.0026 226.562 38.4929 228.302 38.4779L233.228 38.4354C234.684 38.4229 235.913 39.469 235.887 40.924C235.882 41.1722 235.873 41.4364 235.858 41.7154H237.291V68.5733Z" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask2_6154_5894)">
<path d="M174.936 37.777C174.936 54.791 187.424 68.0335 205.444 68.0335C222.877 68.0335 236.036 56.0482 236.036 37.777C236.036 20.1762 222.877 7.52043 205.444 7.52043C188.011 7.52043 174.936 19.5057 174.936 37.777ZM185.832 37.777C185.832 26.0431 194.464 17.1589 205.444 17.1589C217.765 17.1589 225.14 26.2108 225.14 37.777C225.14 49.9299 216.507 58.395 205.444 58.395C194.213 58.395 185.832 49.9299 185.832 37.777Z" fill={color}/>
</g>
<mask id="mask3_6154_5894" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="306" y="38" width="63" height="31">
<path fillRule="evenodd" clipRule="evenodd" d="M368.69 68.5733H306.021V41.7154H306.549C306.532 41.4129 306.521 41.1274 306.516 40.8604C306.49 39.4055 307.72 38.3593 309.175 38.3719L314.101 38.4144C315.851 38.4294 317.291 39.9562 317.585 41.681C317.587 41.6925 317.589 41.7039 317.591 41.7154H356.222C356.527 40.0026 357.961 38.4929 359.701 38.4779L364.627 38.4354C366.082 38.4229 367.312 39.469 367.286 40.924C367.281 41.1722 367.272 41.4364 367.256 41.7154H368.69V68.5733Z" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask3_6154_5894)">
<path d="M306.335 37.777C306.335 54.791 318.823 68.0335 336.843 68.0335C354.276 68.0335 367.434 56.0482 367.434 37.777C367.434 20.1762 354.276 7.52043 336.843 7.52043C319.41 7.52043 306.335 19.5057 306.335 37.777ZM317.23 37.777C317.23 26.0431 325.863 17.1589 336.843 17.1589C349.163 17.1589 356.539 26.2108 356.539 37.777C356.539 49.9299 347.906 58.395 336.843 58.395C325.612 58.395 317.23 49.9299 317.23 37.777Z" fill={color}/>
</g>
<path d="M150.822 68.669H146.307C144.464 68.669 142.898 67.1026 142.898 65.2597V14.7646H125.391C123.548 14.7646 121.981 13.1982 121.981 11.3553V7.5774C121.981 5.73451 123.548 4.16806 125.391 4.16806H171.739C173.582 4.16806 175.148 5.73451 175.148 7.5774V11.3553C175.148 13.1982 173.582 14.7646 171.739 14.7646H154.232V65.2597C154.232 67.1026 152.665 68.669 150.822 68.669Z" fill={color}/>
<path d="M103.885 39.6436V7.5774C103.885 5.73451 105.452 4.16806 107.295 4.16806H111.718C113.561 4.16806 115.127 5.73451 115.127 7.5774V41.9472C115.127 61.6661 103.056 69.6826 89.511 69.6826C73.4779 69.6826 62.052 60.1918 62.052 41.3022V7.5774C62.052 5.73451 63.6185 4.16806 65.4613 4.16806H69.9764C71.8193 4.16806 73.3857 5.73451 73.3857 7.5774V38.9064C73.3857 50.9773 78.3615 59.086 89.511 59.086C99.2783 59.086 103.885 52.1752 103.885 39.6436Z" fill={color}/>
<path d="M10.6887 66.0889L20.7325 41.5785L30.2233 18.6346L49.6658 66.0889C50.2186 67.6553 51.7851 68.6689 53.4437 68.6689H57.4059C59.5252 68.6689 60.9995 67.0103 60.9995 65.0753C60.9995 64.7067 60.9073 64.246 60.7231 63.7853L36.5813 6.56371C35.9363 4.99726 34.3698 3.98367 32.7112 3.98367H28.2883C26.6297 3.98367 25.0632 4.99726 24.4182 6.56371L0.276433 63.7853C0.0921442 64.246 0 64.7067 0 65.0753C0 67.0103 1.47431 68.6689 3.59363 68.6689H6.91082C8.56942 68.6689 10.1359 67.6553 10.6887 66.0889Z" fill={color}/>
</svg>
    </div>
  );
}
